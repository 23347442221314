
export function BossWantsFileCheckEmail() {
    return (
        <div className="emailContent">
            <p>
                Good morning,
            </p>

            <p>
                Could you look over this file for me?
            </p>

            {/* TODO: "File Attachment" image here */}

            <p>
                Thanks,
                <br></br>
                Your Boss
            </p>
        </div>
    )
}